#root {
  width: 100%;
}

body {
  width: 100%;
  margin: 0px;
  display: flex;
  font-family: "Patrick Hand SC", cursive;
  font-size: 24px;
  line-height: 1;
  text-align: center;
}

main {
  padding: 30px;
  text-align: center;
}

wired-card {
  max-width: 320px;
  margin: 0 auto;
  padding: 20px;
  display: block;
}

wired-tabs {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  display: block;
}

wired-tab {
  max-width: 380px;
  margin: 0 auto;
  display: block;
}

section {
  max-width: 400px;
  margin: 0 auto;
  padding: 60px 0;
}

wired-button {
  background: yellow;
  color: red;
  margin: 8px;
}
wired-input {
  width: 200px;
  font-family: inherit;
}

.next-button {
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
}

.card-highlightable:hover {
  background: lightgreen;
  cursor: pointer;
}

.popover-inner {
  font-family: "ABeeZee", cursive;
}
.popover-header {
  font-size: 16px;
}

.popover-body {
  line-height: 1.2;
  font-size: 14px;
}

.wired-rendered {
  flex-flow: wrap;
}

.text-underline {
  text-decoration: underline;
}

wired-button {
  background: white;
  color: black;
}
