.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.navbar {
  display: flex;
  align-items: center;
  z-index: 99999;
  position: fixed;
  background: white;
  color: black;
  font-weight: 300;
  width: 100%;
  border-bottom: 1px solid #818181;
}

.nav-space {
  height: 80px;
}

.info-box {
  float: right;
  cursor: pointer;
}

.something: {
  border: 1px solid black;
}

wired-tabs:focus {
  outline: none;
}

.centered-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
